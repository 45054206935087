/* exo-100 - latin */
@font-face {
	font-family: 'Exo';
	font-style: normal;
	font-weight: 100;
	src: url('/assets/fonts/exo-v12-latin-100.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('/assets/fonts/exo-v12-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/assets/fonts/exo-v12-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
		url('/assets/fonts/exo-v12-latin-100.woff') format('woff'), /* Modern Browsers */
		url('/assets/fonts/exo-v12-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/assets/fonts/exo-v12-latin-100.svg#Exo') format('svg'); /* Legacy iOS */
	font-display: swap;
}
/* exo-300 - latin */
@font-face {
	font-family: 'Exo';
	font-style: normal;
	font-weight: 300;
	src: url('/assets/fonts/exo-v12-latin-300.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('/assets/fonts/exo-v12-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/assets/fonts/exo-v12-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
		url('/assets/fonts/exo-v12-latin-300.woff') format('woff'), /* Modern Browsers */
		url('/assets/fonts/exo-v12-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/assets/fonts/exo-v12-latin-300.svg#Exo') format('svg'); /* Legacy iOS */
	font-display: swap;
}
/* exo-regular - latin */
@font-face {
	font-family: 'Exo';
	font-style: normal;
	font-weight: 400;
	src: url('/assets/fonts/exo-v12-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('/assets/fonts/exo-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/assets/fonts/exo-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		url('/assets/fonts/exo-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
		url('/assets/fonts/exo-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/assets/fonts/exo-v12-latin-regular.svg#Exo') format('svg'); /* Legacy iOS */
	font-display: swap;
}
/* exo-500 - latin */
@font-face {
	font-family: 'Exo';
	font-style: normal;
	font-weight: 500;
	src: url('/assets/fonts/exo-v12-latin-500.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('/assets/fonts/exo-v12-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/assets/fonts/exo-v12-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
		url('/assets/fonts/exo-v12-latin-500.woff') format('woff'), /* Modern Browsers */
		url('/assets/fonts/exo-v12-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/assets/fonts/exo-v12-latin-500.svg#Exo') format('svg'); /* Legacy iOS */
	font-display: swap;
}
/* exo-700 - latin */
@font-face {
	font-family: 'Exo';
	font-style: normal;
	font-weight: 700;
	src: url('/assets/fonts/exo-v12-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('/assets/fonts/exo-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/assets/fonts/exo-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		url('/assets/fonts/exo-v12-latin-700.woff') format('woff'), /* Modern Browsers */
		url('/assets/fonts/exo-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/assets/fonts/exo-v12-latin-700.svg#Exo') format('svg'); /* Legacy iOS */
	font-display: swap;
}
/* exo-900 - latin */
@font-face {
	font-family: 'Exo';
	font-style: normal;
	font-weight: 900;
	src: url('/assets/fonts/exo-v12-latin-900.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('/assets/fonts/exo-v12-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/assets/fonts/exo-v12-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
		url('/assets/fonts/exo-v12-latin-900.woff') format('woff'), /* Modern Browsers */
		url('/assets/fonts/exo-v12-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/assets/fonts/exo-v12-latin-900.svg#Exo') format('svg'); /* Legacy iOS */
	font-display: swap;
}
/* exo-100italic - latin */
@font-face {
	font-family: 'Exo';
	font-style: italic;
	font-weight: 100;
	src: url('/assets/fonts/exo-v12-latin-100italic.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('/assets/fonts/exo-v12-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/assets/fonts/exo-v12-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
		url('/assets/fonts/exo-v12-latin-100italic.woff') format('woff'), /* Modern Browsers */
		url('/assets/fonts/exo-v12-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/assets/fonts/exo-v12-latin-100italic.svg#Exo') format('svg'); /* Legacy iOS */
	font-display: swap;
}
/* exo-300italic - latin */
@font-face {
	font-family: 'Exo';
	font-style: italic;
	font-weight: 300;
	src: url('/assets/fonts/exo-v12-latin-300italic.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('/assets/fonts/exo-v12-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/assets/fonts/exo-v12-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
		url('/assets/fonts/exo-v12-latin-300italic.woff') format('woff'), /* Modern Browsers */
		url('/assets/fonts/exo-v12-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/assets/fonts/exo-v12-latin-300italic.svg#Exo') format('svg'); /* Legacy iOS */
	font-display: swap;
}
/* exo-italic - latin */
@font-face {
	font-family: 'Exo';
	font-style: italic;
	font-weight: 400;
	src: url('/assets/fonts/exo-v12-latin-italic.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('/assets/fonts/exo-v12-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/assets/fonts/exo-v12-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
		url('/assets/fonts/exo-v12-latin-italic.woff') format('woff'), /* Modern Browsers */
		url('/assets/fonts/exo-v12-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/assets/fonts/exo-v12-latin-italic.svg#Exo') format('svg'); /* Legacy iOS */
	font-display: swap;
}
/* exo-500italic - latin */
@font-face {
	font-family: 'Exo';
	font-style: italic;
	font-weight: 500;
	src: url('/assets/fonts/exo-v12-latin-500italic.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('/assets/fonts/exo-v12-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/assets/fonts/exo-v12-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
		url('/assets/fonts/exo-v12-latin-500italic.woff') format('woff'), /* Modern Browsers */
		url('/assets/fonts/exo-v12-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/assets/fonts/exo-v12-latin-500italic.svg#Exo') format('svg'); /* Legacy iOS */
	font-display: swap;
}
/* exo-700italic - latin */
@font-face {
	font-family: 'Exo';
	font-style: italic;
	font-weight: 700;
	src: url('/assets/fonts/exo-v12-latin-700italic.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('/assets/fonts/exo-v12-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/assets/fonts/exo-v12-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
		url('/assets/fonts/exo-v12-latin-700italic.woff') format('woff'), /* Modern Browsers */
		url('/assets/fonts/exo-v12-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/assets/fonts/exo-v12-latin-700italic.svg#Exo') format('svg'); /* Legacy iOS */
	font-display: swap;
}
/* exo-900italic - latin */
@font-face {
	font-family: 'Exo';
	font-style: italic;
	font-weight: 900;
	src: url('/assets/fonts/exo-v12-latin-900italic.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('/assets/fonts/exo-v12-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/assets/fonts/exo-v12-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
		url('/assets/fonts/exo-v12-latin-900italic.woff') format('woff'), /* Modern Browsers */
		url('/assets/fonts/exo-v12-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/assets/fonts/exo-v12-latin-900italic.svg#Exo') format('svg'); /* Legacy iOS */
	font-display: swap;
}

/* ================================================================================================ */

/* ubuntu-300 - latin */
@font-face {
	font-family: 'Ubuntu';
	font-style: normal;
	font-weight: 300;
	src: url('/assets/fonts/ubuntu-v15-latin-300.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('/assets/fonts/ubuntu-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/assets/fonts/ubuntu-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
		url('/assets/fonts/ubuntu-v15-latin-300.woff') format('woff'), /* Modern Browsers */
		url('/assets/fonts/ubuntu-v15-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/assets/fonts/ubuntu-v15-latin-300.svg#Ubuntu') format('svg'); /* Legacy iOS */
	font-display: swap;
}
/* ubuntu-300italic - latin */
@font-face {
	font-family: 'Ubuntu';
	font-style: italic;
	font-weight: 300;
	src: url('/assets/fonts/ubuntu-v15-latin-300italic.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('/assets/fonts/ubuntu-v15-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/assets/fonts/ubuntu-v15-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
		url('/assets/fonts/ubuntu-v15-latin-300italic.woff') format('woff'), /* Modern Browsers */
		url('/assets/fonts/ubuntu-v15-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/assets/fonts/ubuntu-v15-latin-300italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
	font-display: swap;
}
/* ubuntu-regular - latin */
@font-face {
	font-family: 'Ubuntu';
	font-style: normal;
	font-weight: 400;
	src: url('/assets/fonts/ubuntu-v15-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('/assets/fonts/ubuntu-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/assets/fonts/ubuntu-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		url('/assets/fonts/ubuntu-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
		url('/assets/fonts/ubuntu-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/assets/fonts/ubuntu-v15-latin-regular.svg#Ubuntu') format('svg'); /* Legacy iOS */
	font-display: swap;
}
/* ubuntu-italic - latin */
@font-face {
	font-family: 'Ubuntu';
	font-style: italic;
	font-weight: 400;
	src: url('/assets/fonts/ubuntu-v15-latin-italic.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('/assets/fonts/ubuntu-v15-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/assets/fonts/ubuntu-v15-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
		url('/assets/fonts/ubuntu-v15-latin-italic.woff') format('woff'), /* Modern Browsers */
		url('/assets/fonts/ubuntu-v15-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/assets/fonts/ubuntu-v15-latin-italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
	font-display: swap;
}
/* ubuntu-500 - latin */
@font-face {
	font-family: 'Ubuntu';
	font-style: normal;
	font-weight: 500;
	src: url('/assets/fonts/ubuntu-v15-latin-500.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('/assets/fonts/ubuntu-v15-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/assets/fonts/ubuntu-v15-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
		url('/assets/fonts/ubuntu-v15-latin-500.woff') format('woff'), /* Modern Browsers */
		url('/assets/fonts/ubuntu-v15-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/assets/fonts/ubuntu-v15-latin-500.svg#Ubuntu') format('svg'); /* Legacy iOS */
	font-display: swap;
}
/* ubuntu-500italic - latin */
@font-face {
	font-family: 'Ubuntu';
	font-style: italic;
	font-weight: 500;
	src: url('/assets/fonts/ubuntu-v15-latin-500italic.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('/assets/fonts/ubuntu-v15-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/assets/fonts/ubuntu-v15-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
		url('/assets/fonts/ubuntu-v15-latin-500italic.woff') format('woff'), /* Modern Browsers */
		url('/assets/fonts/ubuntu-v15-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/assets/fonts/ubuntu-v15-latin-500italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
	font-display: swap;
}
/* ubuntu-700 - latin */
@font-face {
	font-family: 'Ubuntu';
	font-style: normal;
	font-weight: 700;
	src: url('/assets/fonts/ubuntu-v15-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('/assets/fonts/ubuntu-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/assets/fonts/ubuntu-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		url('/assets/fonts/ubuntu-v15-latin-700.woff') format('woff'), /* Modern Browsers */
		url('/assets/fonts/ubuntu-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/assets/fonts/ubuntu-v15-latin-700.svg#Ubuntu') format('svg'); /* Legacy iOS */
	font-display: swap;
}
/* ubuntu-700italic - latin */
@font-face {
	font-family: 'Ubuntu';
	font-style: italic;
	font-weight: 700;
	src: url('/assets/fonts/ubuntu-v15-latin-700italic.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('/assets/fonts/ubuntu-v15-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/assets/fonts/ubuntu-v15-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
		url('/assets/fonts/ubuntu-v15-latin-700italic.woff') format('woff'), /* Modern Browsers */
		url('/assets/fonts/ubuntu-v15-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/assets/fonts/ubuntu-v15-latin-700italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
	font-display: swap;
}
