body {
	/** Color variables **/
	--le-color-primary: #112d4e;
	--le-color-primary-tint: #1a4578;
	--le-color-primary-shade: #081524;
	--le-color-primary-contrast: #fff;

	--le-color-secondary: #CBB26B;
	--le-color-secondary-gradient: linear-gradient(to right, rgb(184, 135, 70) 0%, rgb(253, 245, 166) 100%);
	--le-color-secondary-tint: #fbf2a4;
	--le-color-secondary-shade: #b88746;
	--le-color-secondary-contrast: #112d4e;

	--le-color-info: #255ea3;
	--le-color-success: #5aba5e;
	--le-color-warning: #ffa219;
	--le-color-danger: #ff5346;

	/** Font variables **/
	--font-size-base: 14px;
	--font-family-heading: 'Exo';
	--font-family-paragraph: 'Ubuntu';

	/** Misc. variables **/
	--border-width: 1px;
	--border-color: var(--le-color-primary);
	--border-style: solid;
	--border-radius: 6px;
	--box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.25);

	/** Input field variables **/
	--input-border-width: var(--border-width);
	--input-border-color: var(--border-color);
	--input-border-style: var(--border-style);
	--input-border-radius: var(--border-radius);
	--input-text-color: var(--le-color-primary);

	/** Button variables **/
	--button-border-radius: var(--border-radius);
}
