/* You can add global styles to this file, and also import other style files */

html, body {
	height: 100%;
	margin: 0;
	font-family: var(--font-family-paragraph);
	font-weight: 400;
	font-size: var(--font-size-base);
    // overflow: overlay;

	h1, h2, h3, h4, h5, h6 {
		font-family: var(--font-family-heading);
	}
	&.showing-splashscreen {
		pointer-events: none;
		overflow: hidden;
	}
	// subtle scrollbar
	::-webkit-scrollbar {
		width: 5px;
	}
	::-webkit-scrollbar-track {
		background: rgba(0, 0, 0, 0.8);
	}
	::-webkit-scrollbar-thumb {
		background: #555;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #111;
	}

	le-root {
		display: flex;
		flex-flow: column;
		height: 100%;
	}
}
